import { VisIcon } from '@zeiss/ng-vis-common/types';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
const ROUTE_PATHS = {
  _: 'skillmatrix',
  metadata: 'metadata',
  skillsOverview: 'skills-overview',
  deputyList: 'deputy-list'
};
const ROUTE_CONFIG = {
  path: ROUTE_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'vp.skillmatrix.routes.title',
    description: 'vp.skillmatrix.routes.desc',
    icon: VisIcon.ALERT_STATUS_DEFAULT,
    showInMenu: true,
    cache: true,
    rule: vpRule({
      rule: RULE.access_skillmatrix,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: ROUTE_PATHS.skillsOverview,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.skillmatrix.routes.skills.title',
        description: 'vp.skillmatrix.routes.skills.desc',
        icon: VisIcon.QUALIFICATION_MEASUREMENT,
        showInMenu: true,
        cache: true,
        hierarchy: 2,
        rule: vpRule({
          rule: RULE.access_skillmatrix,
          required: ['overview']
        })
      }
    }, {
      path: ROUTE_PATHS.deputyList,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.skillmatrix.routes.deputyList.title',
        description: 'vp.skillmatrix.routes.deputyList.desc',
        icon: VisIcon.USER_GROUP,
        showInMenu: true,
        cache: true,
        hierarchy: 1,
        rule: vpRule({
          rule: RULE.access_skillmatrix,
          required: ['deputy_list']
        })
      }
    }, {
      path: ROUTE_PATHS.metadata,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.skillmatrix.routes.metadata.title',
        description: 'vp.skillmatrix.routes.metadata.desc',
        icon: VisIcon.SYSTEM_PARAMETERS,
        showInMenu: true,
        cache: true,
        hierarchy: 0,
        rule: vpRule({
          rule: RULE.access_skillmatrix,
          required: ['metadata']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { ROUTE_CONFIG, ROUTE_PATHS };
